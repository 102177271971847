<template>
  <v-app>
    <div>
      <v-app-bar dark app>
        <v-toolbar-title
          style="cursor: pointer"
          @click="$router.push('/'), scrollTop()"
        >
          <div class="header_logo" oncontextmenu="return false;">
            <img
              src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/logo_transparent1.png"
              alt=""
              controlsList="nodownload"
            />
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="isLoggedIn">
          <v-list-item href="/login" @click="ga.mainPage.login(this.$ga)">
            <v-list-item-title>
              ログイン
              <v-icon right small>
                mdi-login
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </div>

        <div class="Header_profile" v-else>
          <div
            class="Header_profile_wrapper"
            style="cursor: pointer"
            @click="profile"
          >
            <div class="Avatar_wrapper">
              <img
                alt=""
                v-bind:src="this.user.profile_icon"
                v-bind:data-src="this.user.profile_icon"
              />
            </div>
            <div class="Nickname">
              <div class="Nickname_wrapper">
                <span class="Nickname_span">{{ this.user.nickname }}</span>
              </div>
            </div>
          </div>

          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <div>
                <v-list-item @click="profile">
                  <v-list-item-title>
                    プロフィール
                    <v-icon right small>
                      mdi-account
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  href="/block/receive"
                  @click="ga.mainPage.block_rec(this.$ga)"
                >
                  <v-list-item-title>
                    ブロックリスト
                    <v-icon right small>
                      mdi-account-cancel
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item href="/settng/pin_change">
                  <v-list-item-title>
                    投稿ピン変更
                    <v-icon right small>
                      mdi-pin
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title>
                    ログアウト
                    <v-icon right small>
                      mdi-logout
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
    </div>

    <v-main class="main">
      <router-view />
    </v-main>

    <transition name="fade">
      <div class="page_top" v-show="visible">
        <a @click="scrollTop">
          <v-icon class="top_icon" color="grey darken-3" x-large>
            mdi-chevron-up
          </v-icon>
        </a>
      </div>
    </transition>

    <div class="footer">
      <div class="address">
        <a href="/privacy" @click="ga.mainPage.privacy(this.$ga)"
          >プライバシーポリシー</a
        >
        <a href="/terms" @click="ga.mainPage.terms(this.$ga)">利用規約</a>
        <span>Copyright © 2021 Bexix Pbss. All Rights Reserved.</span>
      </div>
    </div>
  </v-app>
</template>

<script>
import ga from "@/plugins/ga";

export default {
  data: function() {
    return {
      user: {
        id: null,
        username: "none",
        nickname: null,
        is_online: null,
        online_status: null,
        profile_icon: null,
      },
      visible: false,
    };
  },
  created: function() {
    this.user.id = this.$store.getters.userId;
    if (this.user.id != "") {
      this.axios
        .get(
          "https://cors.bexix.net/https://api.yay.space/v2/users/" +
            this.user.id
        )
        .then((response) => {
          this.user.username = response.data.user.username;
          this.user.nickname = response.data.user.nickname;
          if (response.data.user.is_online == true) {
            this.user.is_online = "公開";
          } else if (response.data.user.is_online == false) {
            this.user.is_online = "非公開";
          }
          this.user.online_status = response.data.user.online_status;
          this.user.profile_icon = response.data.user.profile_icon;
        })
        .catch(() => {
          this.$router.push({
            name: "NotFound",
          });
        });
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.userId === "";
    },
  },
  methods: {
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | BexixWeb";
        document.title = setTitle;
      } else {
        document.title = "より便利と使いやすさをあなたに。 | BexixWeb";
      }
    },
    profile() {
      ga.mainPage.profile(this.$ga);
      this.$router.push({
        name: "Profile",
        query: { id: [this.$store.getters.userId] },
      });
    },
    logout() {
      this.$store.dispatch("auth", {
        userId: "",
        userToken: "",
      });
      ga.mainPage.logout(this.$ga);
      this.$router.push("/login");
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.visible) {
        this.visible = 30 < window.scrollY;
      } else if (30 > window.scrollY) {
        this.visible = !this.visible;
      }
    },
  },
  mounted: function() {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
};
</script>

<style>
body {
  width: 100%;
}

.header_logo {
  display: flex;
}

@media (min-width: 750px) {
  .header_logo {
    width: 135px;
  }
}

.header_logo img {
  margin: 0 auto;
  height: 50px;
}

.Header_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_profile_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 0 10px;
}

.Avatar_wrapper {
  display: flex;
  font-size: 15px;
  height: auto;
  width: auto;
  position: relative;
  border-radius: inherit;
  margin: 0 10px;
}

.Avatar_wrapper img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.Avatar_wrapper .Nickname {
  max-width: 200px;
}

.Nickname_wrapper {
  display: inline-grid;
  grid-template-columns: 1fr max-content;
  grid-gap: calc(var(--global-space) * 0.25);
  align-items: center;
}

.Nickname_span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_top {
  width: 90px;
  height: 90px;
  position: fixed;
  right: 0;
  bottom: 0;
  opacity: 0.6;
}

@media (max-width: 800px) {
  .page_top {
    width: 70px;
    height: 85px;
  }
}

.page_top a {
  position: relative;
  display: block;
  width: 70px;
  height: 60px;
  text-decoration: none;
  text-align: center;
}

@media (max-width: 800px) {
  .page_top a {
    width: 50px;
    height: 50px;
  }
}

.page_top a:hover {
  opacity: 0.5;
}

.page_top a::after {
  content: "PAGE TOP";
  font-size: 13px;
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  color: #424242;
}

@media (max-width: 800px) {
  .page_top a::after {
    font-size: 9px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave {
  opacity: 0;
}

.footer {
  text-align: center;
  height: 75px;
  line-height: 20px;
  background-color: #272727;
  color: #cdcbc2;
  font-size: 0.85em;
  padding: 15px;
}

.footer a {
  color: #cdcbc2 !important;
  text-decoration: none;
  margin: 0 12px;
}

.footer a:hover {
  opacity: 0.5;
}

.footer span {
  margin-top: 5px;
  display: block;
}
</style>
